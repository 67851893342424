import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/images/Logo.png";
import MyHeader from "../Components/MyHeader";

function ThankYou2(props) {
  const { t } = useTranslation();

  return (
    <>
      <MyHeader />
      <Stack className="container">
        <LogoDiv>
          <LogoImg style={{ backgroundImage: `url(${Logo})` }}></LogoImg>
        </LogoDiv>

        <WhiteRect className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 text-center">
            <hr className="my-4 w-50 mx-auto" />
            <LoremIpsum> {t("thank_you")}</LoremIpsum>
            <Caption className="text-center">
              {t("your_video_success_upload")}
            </Caption>

            <p className="px-5 text-center">
              <LoremIpsum2>{t("record_again")} :</LoremIpsum2>
              <Link style={{ textDecoration: "none" }} to="/">
                <LoremIpsum3>
                  {" "}
                  <b>{t("home")}</b>
                </LoremIpsum3>
              </Link>
            </p>
          </div>
          <div className="col-md-2"></div>
        </WhiteRect>
      </Stack>
    </>
  );
}

const Stack = styled.div`
flex-grow: 1
  background-color: rgba(255, 255, 255, 1);
`;

const WhiteRect = styled.div``;

const LoremIpsum = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: #2ba049;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  text-align: center;
  line-height: 24px;
  margin: 0;
`;

const Caption = styled.p`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  text-align: center;
  line-height: 24px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum2 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum3 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  color: #2ba049;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LogoDiv = styled.div`
  padding: 24px;
`;

const LogoImg = styled.div`
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default ThankYou2;
