import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/images/Logo.png";
import MyHeader from "../Components/MyHeader";

function Terms(props) {
  return (
    <>
      <MyHeader />
      <Stack className="container">
        <LogoDiv>
          <LogoImg style={{ backgroundImage: `url(${Logo})` }}></LogoImg>
        </LogoDiv>

        <WhiteRect className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <hr className="my-4 w-50 mx-auto" />
            <LoremIpsum>Terms of Use</LoremIpsum>
            <Caption>
              Please read these terms and conditions of use (these "Terms and
              Conditions") carefully. By accessing or using the rashtragaan.in
              domain, you agree that you have read, understand and agree to
              comply with and to be bound by these Terms and Conditions, whether
              or not you are a registered member of the Website or other
              Services.
            </Caption>
            <Caption>
              Please also read our Privacy Policy and Disclaimer carefully to
              understand what we do with the personal information that we
              collect as part of your interaction with the website.These Terms
              and Conditions, including without limitation the Privacy Policy
              and Disclaimer, govern your use of the Rashtragaan.in and
              constitute a legal contract between you and website.
            </Caption>
            <Caption>
              If you do not agree to abide by these terms and conditions,
              immediately discontinue your access on the website and do not
              return. Any breach of these terms and conditions immediately
              terminates your right to access and all rights and licenses
              granted to you by these terms and conditions.
            </Caption>
            <Caption>
              These Terms and Conditions concern are limited only to the website
              Rashtragaan.in and do not concern any other website or
              Internet-based services.
            </Caption>
            <Caption>
              <b>A. USE OF THE WEBSITE</b>
            </Caption>
            <Caption>
              This website is affiliated with the Ministry of Culture,
              Government of India. It is an initiative with the sole purpose to
              commemorate Azadi ke Amrit Mahotsav. Users are invited to record
              and sing the National Anthem. The following steps are followed to
              complete the process till submission lead page :
            </Caption>
            <Caption>
              <ol>
                <li>Provision of Name</li>
                <li>Selection of Age amongst different age group segments</li>
                <li>Allowance of the access of Camera and Microphone</li>
                <li>Users record their video singing the National Anthem</li>
                <li>Available options to re-start or stop</li>
                <li>Submission of the song</li>
              </ol>
            </Caption>
            <Caption>
              <b>B. PURPOSE OF GATHERING PERSONAL DATA</b>
            </Caption>
            <Caption>
              Information is collected to fulfill the purpose of the website and
              generation of the final product. The videos collected from each
              user will be amalgamated into a single video to be showcased on
              the 15th August 2021 in .
            </Caption>
            <Caption>
              The personal information is not used or disclosed by any means at
              any point in the process or thereafter. Only the final product
              submitted by the user will be used as a part of the final video.
            </Caption>
            <Caption>
              <b>C. CONTENT PROPERTY </b>
            </Caption>
            <Caption>
              The content provided through Rashtragaan.in , including but not
              limited to any text, illustrations, files, images, scripts,
              graphics, music, sounds, photographs, videos, information content,
              URLs, interactive features, documentation, or other materials
              (collectively, the "Content") and all underlying software or
              technology used in connection with the website ("Technology"),
              including but not limited to all intellectual property rights in
              and to the Content and Technology, is and remains at all times the
              property of Rashtragaan.in , as the case may be, with all rights
              not explicitly granted herein reserved to Rashtragaan.in , as
              applicable.
            </Caption>
            <Caption>
              Any use of the Content other than as expressly authorized herein
              is strictly prohibited and shall immediately terminate your right
              to access and use the Services and all rights and licenses granted
              to you by these Terms and Conditions.The trademarks, service
              marks, trade names and logos, including without limitation
              Rashtragaan.in and any third party marks used and displayed
              through the website are trademarks of the Government of India.
            </Caption>
            <Caption>
              <b>D. SUGGESTIONS AND FEEDBACK </b>
            </Caption>
            <Caption>
              Please note that we do accept or consider ideas, suggestions or
              materials. If you send us suggestions, ideas, notes, drawings,
              concepts or feedback related to the Services, all such submissions
              shall be and are hereby deemed to be confidential, and we shall
              not be and hereby is not liable for any use or disclosure of any
              such submissions.
            </Caption>
            <Caption>
              <b>F. THIRD PARTY SITES </b>
            </Caption>
            <Caption>
              These Terms and Conditions only relate to your use of the website
              and do not relate to any other website or Internet-based services,
              including websites to which the Services may link ("Third-Party
              Sites"). We are not responsible or liable for any content,
              advertising, products, services, information or other materials on
              or available on Third-Party Sites.
            </Caption>
            <Caption>
              <b>G. TERMINATION </b>
            </Caption>
            <Caption>
              This website reserves the right, in its sole discretion, to
              refuse, suspend, restrict or terminate your access or any portion
              thereof, without notice and for any reason or no reason.
            </Caption>

            <hr className="my-4 w-50 mx-auto" />
            <p className="px-5 text-center">
              <LoremIpsum2>Go back:</LoremIpsum2>
              <Link style={{ textDecoration: "none" }} to="/">
                <LoremIpsum3>
                  {" "}
                  <b>Home</b>
                </LoremIpsum3>
              </Link>
            </p>
          </div>
          <div className="col-md-2"></div>
        </WhiteRect>
      </Stack>
    </>
  );
}

const Stack = styled.div`
flex-grow: 1
  background-color: rgba(255, 255, 255, 1);
`;

const WhiteRect = styled.div``;

const LoremIpsum = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: #2ba049;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 16px;
  text-align: center;
  line-height: 24px;
  margin: 0;
`;

const Caption = styled.p`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 0.75rem;
`;

const LoremIpsum2 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum3 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  color: #2ba049;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LogoDiv = styled.div`
  padding: 24px;
`;

const LogoImg = styled.div`
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default Terms;
