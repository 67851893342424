import React from "react";
import styled from "styled-components";
import { Icon } from "@iconify/react";
import recordCircle from "@iconify-icons/mdi/record-circle";
import restartIcon from "@iconify-icons/mdi/restart";
import progressUpload from "@iconify-icons/mdi/progress-upload";
import AudioSound from "../assets/Jana_Gana_Mana_instrumental.mp3";
import firebase from "firebase/app";
import "firebase/storage";
import swal from "sweetalert";
import ProfileOverlay from "../assets/images/svg5.svg";
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import MyHeader from "../Components/MyHeader";

const erroPageUrl = "/UserPermissions/";

class ThirdScreen extends React.Component {
  state = {
    status: null,
    counter: 3,
    subtitleTime: 0,
  };

  componentDidMount() {
    this.setWebCamStream();
  }

  setWebCamStream() {
    video = document.getElementById("video");
    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        theStream = stream;
        video.srcObject = stream;
      })
      .catch((e) => {
        if (e instanceof DOMException) {
          switch (e.name) {
            case "NotAllowedError":
              swal({
                title: "User Permissions Denied",
                icon: "warning",
                text: "Please allow Camera & Mic",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });

              break;
            case "AbortError":
              swal({
                title: "Webcam not accesible / Hardware error",
                icon: "warning",
                text: "Please check your hardware devices",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });
              break;
            case "NotFoundError":
              swal({
                icon: "warning",
                text: "No Media track was found",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });
              break;
            case "NotReadableError":
              swal({
                icon: "warning",
                text: "Your Webcam is not accesible",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });
              break;
            case "OverconstrainedError":
              swal({
                icon: "warning",
                text: "Overconstrained Error",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });
              break;
            case "SecurityError":
              swal({
                icon: "warning",
                text: "Webcam disabled on user device (Security)",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });
              break;
            case "TypeError":
              swal({
                icon: "warning",
                text: "Insecure Context",
                dangerMode: true,
              }).then(() => {
                window.location = erroPageUrl;
              });
              break;

            default:
              break;
          }
        }
        console.error("getUserMedia() failed: " + e);
      });
  }

  render() {
    const SubtitleArray = [];
    return (
      <Stack>
        <MyHeader />
        <div className="container pt-4">
          <AnthemContainer className="container pb-4">
            <div className="row">
              <div className="col-md-3"></div>

              <div className="col-md-6">
                <Anthem>
                  <span style={{ opacity: 0 }}>.</span>

                  {this.state.status == null
                    ? this.props.t("press_record_to")
                    : null}

                  {this.state.status === "starting"
                    ? this.props.t("starting_in") + " " + this.state.counter
                    : null}

                  {this.state.status === "ready"
                    ? this.props.t("click_upload")
                    : null}

                  <AnthemActive>
                    {this.state.status === "recording"
                      ? this.props
                        .t("national_anthem_lyrics", { returnObjects: true })
                        .filter((e) => e.time === this.state.subtitleTime)[0]
                        ?.text
                      : null}
                  </AnthemActive>

                  <AnthemInactive>
                    {this.state.status === "recording"
                      ? this.props
                        .t("national_anthem_lyrics", { returnObjects: true })
                        .filter((e) => e.time === this.state.subtitleTime)[0]
                        ?.textInactive
                      : null}
                  </AnthemInactive>
                  <span className="text-center m-0" id="message"></span>
                  <span style={{ opacity: 0 }}>.</span>
                </Anthem>
              </div>
              <div className="col-md-3"></div>
            </div>
          </AnthemContainer>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-12 pb-4">
                  <div className="position-relative overflow-hidden">
                    {this.state.status === "starting" ||
                      this.state.status === null ? (
                      <div
                        className="position-absolute w-100 text-center p-5"
                        style={{
                          backgroundImage: `url(${ProfileOverlay})`,
                          backgroundSize: "contain",
                          backgroundPosition: "center",
                          backgroundRepeat: "no-repeat",
                          opacity: 0.3,
                          height: "98%",
                        }}
                      ></div>
                    ) : null}

                    <video
                      id="video"
                      autoPlay
                      muted
                      webkit-playsinline="true"
                      playsInline
                      style={{
                        border: "2px solid #2BA049",
                        padding: 0,
                        borderRadius: "4px",
                      }}
                      width="100%"
                      controls={false}
                    />
                  </div>
                </div>

                <div
                  className={
                    "text-center col-4 " +
                    (this.state.status == null ? "" : "disable")
                  }
                >
                  <Button
                    className={this.state.status == null ? "btn" : "disable"}
                    onClick={
                      this.state.status == null
                        ? () => {
                          this.setState({ status: "starting" });
                          setInterval(() => {
                            this.setState({
                              counter: this.state.counter - 1,
                            });
                          }, 1000);

                          document.getElementsByTagName(
                            "audio"
                          )[0].muted = true;
                          document.getElementsByTagName("audio")[0].play();
                          setTimeout(() => {
                            initialise();
                            this.setState({ status: "recording" });
                            setInterval(() => {
                              this.setState({
                                subtitleTime: this.state.subtitleTime + 1,
                              });

                              SubtitleArray.push(
                                this.props
                                  .t("national_anthem_lyrics", {
                                    returnObjects: true,
                                  })
                                  .filter(
                                    (e) => e.time === this.state.subtitleTime
                                  )[0]?.text
                              );
                            }, 1000);
                          }, 3000);
                          setTimeout(() => {
                            this.setState({ status: "ready" });
                          }, 65000);
                        }
                        : null
                    }
                    id="start"
                  >
                    <Icon icon={recordCircle} height={24} />
                  </Button>
                  <ButtonTitle className="text-center">
                    {this.props.t("record_btn")}
                  </ButtonTitle>
                </div>

                <div
                  className={
                    "text-center col-4 " +
                    (this.state.status === "ready" ? "" : "disable")
                  }
                >
                  <Button
                    className={
                      this.state.status === "ready" ? "btn" : "disable"
                    }
                    onClick={
                      this.state.status === "ready"
                        ? () => {
                          uploadBlob();
                          this.setState({ status: "uploading" });
                        }
                        : null
                    }
                  >
                    <Icon icon={progressUpload} height={24} />
                  </Button>
                  <ButtonTitle className="text-center">
                    {this.props.t("upload_btn")}
                  </ButtonTitle>
                </div>

                <div
                  className={
                    "text-center col-4 " +
                    (this.state.status == null ? "disable" : "")
                  }
                >
                  <Button
                    className={this.state.status == null ? "disable" : "btn"}
                    onClick={
                      this.state.status == null
                        ? null
                        : () => {
                          this.setState({ status: null });
                          window.location = "/";
                        }
                    }
                  >
                    <Icon icon={restartIcon} height={24} />
                  </Button>
                  <ButtonTitle className="text-center">
                    {this.props.t("restart_btn")}
                  </ButtonTitle>
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>

        <audio style={{ display: "none" }}>
          <source src={AudioSound} type="audio/mpeg" />
          {this.props.t("browser_does_not_support_video")}
        </audio>
      </Stack>
    );
  }
}

export default withTranslation()(ThirdScreen);

const constraints = {
  video: { aspectRatio: 1 },
  audio: true,
};

var blob;
var theStream;
var theRecorder;
var recorder;
var recordedChunks;
var anthem_time = 62000;
var video;

function initialise() {
  recordedChunks = [];
  document.getElementsByTagName("audio")[0].currentTime = 0;
  document.getElementsByTagName("audio")[0].muted = false;

  startRecording();

  //Start a timer for length of National Anthem
  setTimeout(function () {
    showRecordedVideo();

    document.getElementsByTagName("audio")[0].currentTime = 0;
    document.getElementsByTagName("audio")[0].pause();

    // Enable UPLOAD BUTON
    // document.getElementById("upload").disabled = false;
  }, anthem_time);
}

function startRecording(restart) {
  try {
    recorder = new MediaRecorder(theStream);
    theRecorder = recorder;
    recorder.ondataavailable = (event) => {
      recordedChunks.push(event.data);
    };
    recorder.start(100);
  } catch (e) {
    console.error("Exception while creating MediaRecorder: " + e);
    return;
  }
}

function uploadBlob() {
  //console.log("Starting Upload");
  document.getElementById("message").innerHTML = i18next.t("click_upload");
  //Upload BLOB to firebase
  var storageRef = firebase.storage().ref();

  //dynamically set reference to the file name
  var name = randomStr(20, "1234567890abcdefghijklmnopqrstuvwxyz");
  var thisRef = storageRef.child(
    localStorage.getItem("user") +
    "-" +
    localStorage.getItem("age") +
    "-" +
    localStorage.getItem("country") +
    "-" +
    localStorage.getItem("inState") +
    "-" +
    name +
    "-" +
    Math.round(new Date().getTime())
  );

  // Upload file and metadata to the object 'images/mountains.jpg'
  var uploadTask = thisRef.put(blob);

  // Listen for state changes, errors, and completion of the upload.
  uploadTask.on(
    firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
    (snapshot) => {
      // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
      var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      document.getElementById("message").innerHTML =
        i18next.t("uploading") +
        ": " +
        Math.round(progress) +
        "% " +
        i18next.t("done");
      //console.log("Uploading: " + progress + "% done");
      switch (snapshot.state) {
        case firebase.storage.TaskState.PAUSED: // or 'paused'
          console.log("Upload is paused");
          break;
        case firebase.storage.TaskState.RUNNING: // or 'running'
          console.log("Upload is running");
          break;
        default:
          break;
      }
    },
    (error) => {
      // A full list of error codes is available at
      // https://firebase.google.com/docs/storage/web/handle-errors
      switch (error.code) {
        case "storage/unauthorized":
          // User doesn't have permission to access the object
          document.getElementById("message").innerHTML = "No Permission";
          break;
        case "storage/canceled":
          // User canceled the upload
          document.getElementById("message").innerHTML = "Upload Cancelled!";
          // document.getElementById("upload").disabled = false;
          break;

        case "storage/unknown":
          // Unknown error occurred, inspect error.serverResponse
          document.getElementById("message").innerHTML =
            "Unknown error Ocurred! Please Retry!";
          // document.getElementById("upload").disabled = false;
          break;
        default:
          break;
      }
    },
    () => {
      document.getElementById("message").innerHTML =
        i18next.t("upload_complete") + "!";
      setTimeout(function () {
        window.location = "/ThankYou/";
      }, 1000);
      // document.getElementById("upload").disabled = true;
    }
  );
}

function showRecordedVideo() {
  theRecorder.stop();
  theStream.getTracks().forEach((track) => {
    track.stop();
  });

  blob = new Blob(recordedChunks, { type: "video/mp4" });
  var url = URL.createObjectURL(blob);
  //console.log(url);
  video.srcObject = null;
  video.src = url;
  video.loop = true;
  video.controls = true;
  video.muted = false;
  video.play();
  //setTimeout(function() { URL.revokeObjectURL(url); }, 100);
  // document.getElementById("upload").disabled = false;
}

function randomStr(len, arr) {
  var ans = "";
  for (var i = len; i > 0; i--) {
    ans += arr[Math.floor(Math.random() * arr.length)];
  }
  return ans;
}

const Stack = styled.div`
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
`;

const Button = styled.div`
  padding: 8px;
  border-radius: 8px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  color: #faa81c;
  border: 1px solid #faa81c;
  box-sizing: border-box;
  z-index: 1000;
  width: 42px;
  margin: auto;
`;

const ButtonTitle = styled.p`
  font-family: Montserrat;
  font-style: normal;
  line-height: 10px;
  font-size: 12px;
  margin-top: 16px;
`;

const AnthemContainer = styled.div``;

const Anthem = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 20px;
  text-align: center;
  padding: 12px;
  color: #727272;
  background: #ffffff;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`;

const AnthemActive = styled.span`
  color: #faa81c;
  font-weight: 700;
`;

const AnthemInactive = styled.span`
  color: #2ba049;
  font-weight: 700;
`;
