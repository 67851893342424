import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/images/Logo.png";
import MyHeader from "../Components/MyHeader";

function Policy(props) {
  return (
    <>
      <MyHeader />
      <Stack className="container">
        <LogoDiv>
          <LogoImg style={{ backgroundImage: `url(${Logo})` }}></LogoImg>
        </LogoDiv>

        <WhiteRect className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8">
            <hr className="my-4 w-50 mx-auto" />
            <LoremIpsum>Privacy Policy</LoremIpsum>
            <Caption>
              This Privacy Policy shall form a part of the user agreement
              between you and Rashtragaan.
            </Caption>
            <Caption>
              This document is an electronic record in terms of Information
              Technology Act, 2000 and rules there under as applicable and the
              amended provisions pertaining to electronic records in various
              statutes as amended by the Information Technology Act, 2000. This
              electronic record is generated by a computer system and does not
              require any physical or digital signatures.
            </Caption>
            <Caption>
              This document is published in accordance with the provisions of
              Rule 3 (1) of the Information Technology (Intermediaries
              guidelines) Rules, 2011 that require publishing the privacy policy
              for access or usage of rashtragaan.in
            </Caption>
            <Caption>
              By accessing and/or using the website, you signify your agreement
              to accept this privacy policy. If you do not agree with any or all
              of the following terms of the privacy policy, please do not access
              and/or use the website. Providing information to us is your
              choice. You understand that any data, information, content or
              information that you submit to the website will be stored on
              company’s servers for the purposes of making the website available
              to you. Please do not submit to the website any data, information,
              content or information which would include any personal
              information and sensitive personal data or information that you do
              not wish to be made available to the company. If you do not agree
              with any or all of the terms of this privacy policy, please do not
              provide any information to us. If at any time you wish to
              discontinue your access of the website, you are free to do so.
              Further, if you require you can choose to have the personal
              information provided by you deleted by writing to
              info@rashtragaan.in. You always have the choice to opt-out of
              receiving communications from the website by writing to
              info@rashtragaan.in.
            </Caption>
            <Caption>
              We reserve the right, at our sole discretion, to change or modify
              this privacy policy at any time without prior notice. Such changes
              and/or modifications shall become effective immediately upon being
              posted/published on the website herein.
            </Caption>
            <Caption>
              Please review the privacy policy from time-to-time. Your continued
              use of the website following the posting of changes and/or
              modifications will constitute your acceptance of any revised
              privacy policy. The company retains the right at any time to deny
              or suspend access to all or part of the website to anyone who the
              company believes has violated any condition of this privacy
              policy.
            </Caption>
            <Caption>
              <b>Personal identification information</b>
            </Caption>
            <Caption>
              We may collect personal identification information from users in a
              variety of ways, including, but not limited to, when users visit
              our site, fill out a form (optin forms) and in connection with
              other activities, services, features or resources we make
              available on our Site. Users may be asked for, as appropriate,
              name, email address, phone number and IP address. Users may,
              however, visit our site anonymously. We will collect personal
              identification information from users only if they voluntarily
              submit such information to us. Users can always choose not to
              supply personal identification information, except that it may
              prevent them from engaging in certain site related activities.
            </Caption>
            <Caption>
              The company will never ask you and you must never provide
              sensitive personal data or information to the company or to any
              person/entity representing the company. Any disclosure of
              sensitive personal data or information shall be at your sole risk
              and without any liability to the company (including its directors,
              key managerial personnel, officers and employees). You understand,
              acknowledge and agree that the company or any other person acting
              on behalf of the company shall not in any manner be responsible
              for the authenticity of the personal information or sensitive
              personal data or information provided by you to the company.
            </Caption>

            <Caption>You must not disclose your:</Caption>
            <Caption>
              <ul>
                <li>Passwords;</li>
                <li>
                  Financial information such as bank account details, credit
                  card details, debit card details, secure pin number,
                  passwords, etc.;
                </li>
                <li>Physical, physiological and mental health condition;</li>
                <li>Sexual orientation;</li>
                <li>Medical records and history;</li>
                <li>Biometric information;</li>
                <li>National identification numbers;</li>
                <li>And such other sensitive personal data or information.</li>
              </ul>
            </Caption>
            <Caption>
              Users must note that any information that is freely available or
              accessible in public domain shall not be regarded as personal
              information or sensitive personal data or information for the
              purposes of this privacy policy and the company shall not be
              obliged to take any measures to protect the same since the same is
              freely available in the public domain.
            </Caption>
            <Caption>
              Please note that identity theft and the practice currently known
              as "phishing" are of great concern to the company. We do not and
              will not, at any time, request your credit card information/debit
              card information/financial pin numbers and passwords.
            </Caption>
            <Caption>
              You hereby consent to the collection, storage, use, disclosure,
              transfer, processing of the personal information for the purposes
              set out herein.
            </Caption>
            <Caption>
              We may disclose your personal information in the circumstances set
              out below and you hereby consent to the same to be shared with:
            </Caption>
            <Caption>
              <ul>
                <li>
                  Any third-party service provider to whom disclosure is
                  necessary to enable us to provide you with the services which
                  you wish to access on or through the website;
                </li>
                <li>
                  Any person/entity to whom disclosure is necessary in
                  accordance with applicable law;
                </li>
                <li>
                  Any government or statutory authority or court of law or
                  judicial forum to whom disclosure is necessary in accordance
                  with applicable law;
                </li>
                <li>
                  In circumstances we believe necessary or appropriate to
                  respond to valid claims and legal process, to protect the
                  property and rights of the company, to protect the safety of
                  the public or any person or user, or to prevent or stop any
                  illegal, unethical or legally actionable activity;
                </li>
                <li>
                  Any person/entity to whom disclosure is necessary to enable us
                  to enforce our rights;
                </li>
                <li>
                  To our associate companies, business partners, agents or third
                  parties for the purposes of the services or for any other
                  marketing and promotional activity undertaken by or on behalf
                  of the company;
                </li>
                <li>
                  Any entity that legally acquires the company or its website.
                </li>
              </ul>
            </Caption>
            <Caption>
              We understand the importance of data security and we want your
              browsing experience with us to be as safe as possible. We have
              implemented reasonable safeguards and precautions to protect your
              personal information.
            </Caption>
            <Caption>
              We do not sell, trade, or rent users personal identification
              information to others. We may share generic aggregated demographic
              information not linked to any personal identification information
              regarding visitors and users with our business partners, trusted
              affiliates and advertisers for the purposes outlined above.
            </Caption>
            <Caption>
              Please understand that, while we strive to protect your personal
              data against potential risks and exposures, there is no absolute
              security in the online/ internet sphere. Therefore, you must not
              disclose any information on the website that is sensitive personal
              data or information. You understand that the transmission of
              information over the internet is not completely secure and there
              are risks associated with it. Although we strive to protect your
              personal information, we cannot guarantee the security of the same
              while it is being transmitted to our website and any transmission
              is at your own risk.
            </Caption>
            <Caption>
              <b>Cookies</b>
            </Caption>
            <Caption>
              By default, your web browser will accept cookies, however this can
              be altered by you. You have the option of enabling or disabling
              cookies in your web browser. If you do not want us to install
              cookies, you may change the settings on your web browser to
              disable cookies. However please note that if you disable cookies,
              you may not be able to use all of the features of the website.
            </Caption>
            <Caption>
              The website and/or third parties may use "cookies", and other
              similar tracking technologies (collectively, "tracking
              technologies") to collect information automatically as you browse
              the website and the internet. For the purposes of this clause
              below terms have the meanings as set out below:
            </Caption>
            <Caption>
              By visiting the website whether as a registered user or otherwise,
              you acknowledge, understand and hereby agree that you are giving
              us your consent to track your activities and your use of the
              website through these tracking technologies.
            </Caption>
            <Caption>
              If you do not consent to any part of the privacy terms herein or
              wish to withdraw consent to process your information, then you may
              stop using the website and ask us to remove your information from
              our records by writing to us at info@rashtragaan.in.
            </Caption>
            <Caption>
              <b>Dispute Resolution and Jurisdiction</b>
            </Caption>
            <Caption>
              In the event that the Parties are unable to amicably resolve a
              dispute by mediation, said dispute will be referred to arbitration
              by a sole arbitrator to be appointed by the Company, and the award
              passed by such sole arbitrator will be valid and binding on both
              Parties. The Parties shall bear their own costs for the
              proceedings, although the sole arbitrator may, in his/her sole
              discretion, direct either Party to bear the entire cost of the
              proceedings. The arbitration shall be conducted in English, and
              the seat of Arbitration shall be in Delhi.
            </Caption>
            <Caption>
              The Parties expressly agree that the Terms, Policy and any other
              agreements entered into between the Parties are governed by the
              laws, rules and regulations of India, and that the Courts at
              Delhi, India, shall have exclusive jurisdiction over any disputes
              arising between the Parties.
            </Caption>

            <hr className="my-4 w-50 mx-auto" />
            <p className="px-5 text-center">
              <LoremIpsum2>Go back:</LoremIpsum2>
              <Link style={{ textDecoration: "none" }} to="/">
                <LoremIpsum3>
                  {" "}
                  <b>Home</b>
                </LoremIpsum3>
              </Link>
            </p>
          </div>
          <div className="col-md-2"></div>
        </WhiteRect>
      </Stack>
    </>
  );
}

const Stack = styled.div`
flex-grow: 1
  background-color: rgba(255, 255, 255, 1);
`;

const WhiteRect = styled.div``;

const LoremIpsum = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: #2ba049;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 16px;
  text-align: center;
  line-height: 24px;
  margin: 0;
`;

const Caption = styled.p`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  padding-right: 16px;
  padding-left: 16px;
  font-size: 0.75rem;
`;

const LoremIpsum2 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum3 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  color: #2ba049;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LogoDiv = styled.div`
  padding: 24px;
`;

const LogoImg = styled.div`
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

export default Policy;
