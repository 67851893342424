import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styled from "styled-components";

import { Icon } from "@iconify/react";
import closeIcon from "@iconify-icons/mdi/close";
import languageIcon from "@iconify-icons/uil/language";
import "./DesktopLanguage.css";

const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "hin",
    name: "हिंदी",
  },
  {
    code: "asm",
    name: "অসমীয়া",
  },
  {
    code: "tel",
    name: "తెలుగు",
  },
  {
    code: "guj",
    name: "ગુજરાતી",
  },
  {
    code: "pun",
    name: "ਪੰਜਾਬੀ",
  },
  {
    code: "mal",
    name: "മലയാളം",
  },
  {
    code: "ori",
    name: "ଓଡିଆ",
  },
  {
    code: "mar",
    name: "मराठी",
  },
  {
    code: "kan",
    name: "ಕನ್ನಡ",
  },
  {
    code: "ban",
    name: "বাংলা",
  },
  {
    code: "tam",
    name: "தமிழ்",
  },
];

export default function DesktopLanguage({ nextScreen, onClickNext }) {
  const currentLanguageCode = i18next.language;
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const [show, setshow] = useState("false");

  useEffect(() => {}, [currentLanguage, t]);

  return (
    <>
      <div
        className={`na_desk_lang_options_div border py-2 row w-50  ${
          show ? "" : "na_desk_lang_options_div_active"
        }`}
      >
        {languages.map(({ code, name }, index) => (
          <div
            key={index}
            className="col-3 na_desk_lang_options p-3"
            style={{ cursor: "pointer" }}
            onClick={() => {
              i18next.changeLanguage(code);
              setshow((show) => !show);
              localStorage.setItem("lang", code);
            }}
          >
            {currentLanguageCode === code ? (
              <span
                className="na_desk_lang_option"
                style={{ fontWeight: "bold" }}
              >
                {name}
              </span>
            ) : (
              <span className="na_desk_lang_option">{name}</span>
            )}
          </div>
        ))}
      </div>
      <ButtonNext className="btn" onClick={() => setshow((show) => !show)}>
        {show ? (
          <Icon icon={languageIcon} height={24} color="#ff4d00" />
        ) : (
          <Icon icon={closeIcon} height={24} color="#ff4d00" />
        )}
        <LanguageText className="my-auto">{t("language")}</LanguageText>
      </ButtonNext>
    </>
  );
}

const ButtonNext = styled.button`
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  font-weight: bold;
  color: #ff4d00;
  border-radius: 2px;
  background: #ffffff;
  border: 0.8px solid #ff4d00;
  &:hover {
    color: #ff4d00;
    background: #ffffff;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }
  &:focus {
    color: #ff4d00;
  }
`;

const LanguageText = styled.span`
  padding-left: 24px;
  font-weight: bold;
`;
