import React from "react";
import styled from "styled-components";
import MyDesktop from "../Components/MyDesktop";
import { Icon } from "@iconify/react";
import chevronRight from "@iconify-icons/mdi/chevron-right";
import MyMobile from "../Components/MyMobile";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MyHeader from "../Components/MyHeader";
import fileIcon from "@iconify-icons/mdi/file";
import trayArrowUp from "@iconify-icons/mdi/tray-arrow-up";

import firebase from "firebase/app";
import "firebase/storage";
import i18next from "i18next";

var nextScreen = "/uploadComplete";

class BujurgoUpload extends React.Component {
  constructor(props) {
    super(props);
    this.handleFileSelect = this.handleFileSelect.bind(this);
    this.fileInput = React.createRef();
  }

  state = {
    name: "",
    inState: "",
    warning: false,
  };

  componentDidMount() {
    this.nameInput.focus();
  }

  handleInputChange(property) {
    return (e) => {
      this.setState({
        [property]: e.target.value,
      });
    };
  }

  handleInStateChange(e) {
    this.setState({ inState: e.target.value });
  }

  readFile() {
    document.getElementById("message").innerHTML =
      "Selected file : " + this.fileInput.current.files[0].name;
  }

  handleFileSelect(event) {
    event.preventDefault();
    let element = document.getElementById("selectFile");
    element.click();
  }

  onFinish = (e) => {
    const { name, inState } = this.state;
    localStorage.setItem("user", name);
    localStorage.setItem("inState", inState);

    const upladFileName = {
      name: localStorage.getItem("user"),
      state: localStorage.getItem("inState"),
      time: Math.round(new Date().getTime()),
    };

    const upladFileNameEncoded = btoa(JSON.stringify(upladFileName));

    e.preventDefault();
    var storageRef = firebase.storage().ref();

    var thisRef = storageRef.child("bkbdks/" + upladFileNameEncoded);

    // Upload file and metadata to the object 'images/mountains.jpg'
    var uploadTask = thisRef.put(this.state.video);

    // Listen for state changes, errors, and completion of the upload.
    uploadTask.on(
      firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
      (snapshot) => {
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        document.getElementById("message").innerHTML =
          i18next.t("uploading") +
          ": " +
          Math.round(progress) +
          "% " +
          i18next.t("done");
        console.log("Uploading: " + progress + "% done");
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log("Upload is paused");
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log("Upload is running");
            break;
          case firebase.storage.TaskState.SUCCESS: // or 'running'
            console.log("Upload Complete");
            snapshot.ref.getDownloadURL().then(function (downloadURL) {
              console.log("File available at", downloadURL);
            });
            break;
          default:
            break;
        }
      },
      (error) => {
        // A full list of error codes is available at
        // https://firebase.google.com/docs/storage/web/handle-errors
        switch (error.code) {
          case "storage/unauthorized":
            // User doesn't have permission to access the object
            document.getElementById("message").innerHTML = "No Permission";
            break;
          case "storage/canceled":
            // User canceled the upload
            document.getElementById("message").innerHTML = "Upload Cancelled!";
            // document.getElementById("upload").disabled = false;
            break;

          case "storage/unknown":
            // Unknown error occurred, inspect error.serverResponse
            document.getElementById("message").innerHTML =
              "Unknown error Ocurred! Please Retry!";
            // document.getElementById("upload").disabled = false;
            break;
          default:
            break;
        }
      },
      () => {
        document.getElementById("message").innerHTML =
          i18next.t("upload_complete") + "!";
        setTimeout(function () {
          window.location = nextScreen;
        }, 1000);
        // document.getElementById("upload").disabled = true;
      }
    );
  };

  render() {
    return (
      <>
        <MyHeader />
        <Stack className="container">
          <WhiteRect className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 py-5">
              <GreenTitle className="mb-2 text-center" htmlFor="name">
                {this.props.t("Bujurgo Ki Baat - Desh Ke Sath")}
              </GreenTitle>
              <GreenText className="mb-3 text-center" htmlFor="name">
                {this.props.t("please_enter_your_details")}
              </GreenText>
              <div className="form">
                <div className="mb-3">
                  <label htmlFor="name">{this.props.t("full_name")}</label>
                  <input
                    type="text"
                    className="form-control col-md-3 mx-auto"
                    id="name"
                    placeholder={this.props.t("your_name")}
                    required
                    value={this.state.name === null ? "" : this.state.name}
                    defaultValue={this.state.country}
                    ref={(input) => {
                      this.nameInput = input;
                    }}
                    onChange={this.handleInputChange("name")}
                  />
                  {this.state.warning === true &&
                  (this.state.name === "" || this.state.name === null) ? (
                    <span className="text-danger w-100">
                      {this.props.t("name_is_required")}
                    </span>
                  ) : null}
                </div>

                {/* InState Dropdown */}
                <div className="form-group mb-3 mx-auto">
                  <label htmlFor="inputState">{this.props.t("in_state")}</label>
                  <select
                    value={this.state.inState}
                    onChange={this.handleInStateChange.bind(this)}
                    className="form-control"
                    id="inputState"
                    // defaultValue={this.state.inState}
                    required
                  >
                    <option hidden>{this.props.t("select_state")}</option>
                    <option value="AP">Andhra Pradesh</option>
                    <option value="AN">Andaman and Nicobar Islands</option>
                    <option value="AR">Arunachal Pradesh</option>
                    <option value="AS">Assam</option>
                    <option value="BR">Bihar</option>
                    <option value="CH">Chandigarh</option>
                    <option value="CT">Chhattisgarh</option>
                    <option value="DN">Dadar and Nagar Haveli</option>
                    <option value="DD">Daman and Diu</option>
                    <option value="DL">Delhi</option>
                    <option value="GA">Goa</option>
                    <option value="GJ">Gujarat</option>
                    <option value="HR">Haryana</option>
                    <option value="HP">Himachal Pradesh</option>
                    <option value="JK">Jammu and Kashmir</option>
                    <option value="JH">Jharkhand</option>
                    <option value="KA">Karnataka</option>
                    <option value="KL">Kerala</option>
                    <option value="LK">Ladakh</option>
                    <option value="LD">Lakshadweep</option>
                    <option value="MP">Madhya Pradesh</option>
                    <option value="MH">Maharashtra</option>
                    <option value="MN">Manipur</option>
                    <option value="ML">Meghalaya</option>
                    <option value="MZ">Mizoram</option>
                    <option value="NL">Nagaland</option>
                    <option value="OR">Odisha</option>
                    <option value="PY">Puducherry</option>
                    <option value="PB">Punjab</option>
                    <option value="RJ">Rajasthan</option>
                    <option value="SK">Sikkim</option>
                    <option value="TN">Tamil Nadu</option>
                    <option value="TG">Telangana</option>
                    <option value="TR">Tripura</option>
                    <option value="UP">Uttar Pradesh</option>
                    <option value="UT">Uttarakhand</option>
                    <option value="WB">West Bengal</option>
                  </select>
                  {this.state.warning === true && this.state.inState === "" ? (
                    <span className="text-danger w-100">
                      {this.props.t("state_required")}
                    </span>
                  ) : null}
                </div>

                {/* File Upload */}
                <div className="d-flex">
                  <UploadVideoCard
                    className="shadow-sm border"
                    onClick={this.handleFileSelect}
                  >
                    <Icon icon={fileIcon} height="48" color="orange" />
                  </UploadVideoCard>
                  <span className="px-3 text-center my-auto" id="message">
                    Upload Video ( Max: 2 mins)
                  </span>
                </div>

                <input
                  id="selectFile"
                  type="file"
                  accept="video/mp4,video/x-m4v,video/*"
                  onChange={(e) => {
                    this.setState({ video: e.target.files[0] });
                    this.readFile();
                  }}
                  ref={this.fileInput}
                  style={{ display: "none" }}
                />

                {this.state.warning === true && this.state.video === "" ? (
                  <span className="text-danger w-100">
                    {this.props.t("state_required")}
                  </span>
                ) : null}
              </div>
              <MyDesktop className="align-center">
                <div className="text-center">
                  <NextButton
                    className="btn"
                    onClick={
                      this.state.name && this.state.inState
                        ? (e) => {
                            this.setState({ warning: true });
                            this.onFinish(e);
                            // this.props.history.push(nextScreen);
                          }
                        : () => {
                            this.setState({ warning: true });
                          }
                    }
                  >
                    {this.props.t("upload_btn")}

                    <Icon icon={trayArrowUp} height={20} />
                  </NextButton>
                </div>
              </MyDesktop>
            </div>
            <div className="col-md-2"></div>
          </WhiteRect>
          <MyMobile>
            <BottomGreen>
              <ButtonNext
                className="btn"
                onClick={
                  this.state.name && this.state.inState
                    ? (e) => {
                        this.setState({ warning: true });
                        this.onFinish(e);
                        // this.props.history.push(nextScreen);
                      }
                    : () => {
                        this.setState({ warning: true });
                      }
                }
              >
                <LangText> {this.props.t("upload_btn")}</LangText>

                <Icon
                  icon={trayArrowUp}
                  height={20}
                  color="rgba(236,110,35,1)"
                />
              </ButtonNext>
            </BottomGreen>
          </MyMobile>
        </Stack>
      </>
    );
  }
}

const NextButton = styled.button`
  font-weight: bold;
  color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background: #fd7034;
  &:hover {
    color: #ffffff;
    background: #ff4d00;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }
`;

const Stack = styled.div`
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
`;

const WhiteRect = styled.div``;

const GreenTitle = styled.div`
  font-size: 1.5rem;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: rgba(43, 160, 73, 1);
  z-index: 1000;
`;
const GreenText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  z-index: 1000;
`;

const BottomGreen = styled.div`
  padding: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
`;

const ButtonNext = styled.button`
  background-color: #ffffff;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
`;

const LangText = styled.span`
  font-size: 0.8rem;
  padding-left: 8px;
  color: rgba(236, 110, 35, 1);
  font-weight: bold;
`;

const UploadVideoCard = styled.div`
  background: #ffffff;

  border-radius: 10px;
  padding: 0.5rem;
  cursor: pointer;
`;

export default withTranslation()(withRouter(BujurgoUpload));
