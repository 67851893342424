import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../assets/images/Logo.png";
import Certificate from "../assets/images/certificate.png";
import { Icon } from "@iconify/react";
import download from "@iconify-icons/mdi/cloud-download";
import MyHeader from "../Components/MyHeader";

function ThankYou(props) {
  const { t } = useTranslation();
  //var uri;
  const [imageUri, setUri] = useState(null);
  useEffect(() => {
    if (localStorage.getItem("user")) {
      // Setup the Canvas
      var canvas = document.getElementById("canvas");
      var ctx = canvas.getContext("2d");

      var img = new Image();
      img.src = Certificate;
      img.onload = function () {
        // Set Canvas resolution as per image
        canvas.width = img.width;
        canvas.height = img.height;
        canvas.crossOrigin = "Anonymous";

        // Draw Image & Add Name
        ctx.font = "36pt Arial";
        var name = localStorage.getItem("user");
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);
        ctx.fillStyle = "black";
        ctx.textAlign = "center";
        ctx.fillText(name, canvas.width / 2, canvas.height / 2);

        // Show the Image  in the UI
        img.uri = canvas.toDataURL("image/png");
        setUri(img.uri);
      };
    }
  }, []);

  return (
    <>
      <MyHeader />
      <Stack className="container">
        <LogoDiv>
          <LogoImg style={{ backgroundImage: `url(${Logo})` }}></LogoImg>
        </LogoDiv>

        <WhiteRect className="row">
          <div className="col-md-2"></div>
          <div className="col-md-8 text-center">
            <hr className="my-4 w-50 mx-auto" />
            <LoremIpsum> {t("thank_you")}</LoremIpsum>
            <Caption className="text-center">
              {t("your_video_success_upload")}
            </Caption>
            {localStorage.getItem("user") != null ? (
              localStorage.getItem("user") !== "" ? (
                <span>
                  <canvas id="canvas"> </canvas>
                  <br></br>

                  <ButtonNext
                    className="btn btn-light"
                    onClick={() => {
                      var link = document.createElement("a");
                      link.download = localStorage.getItem("user");
                      link.href = imageUri;
                      document.body.appendChild(link);
                      link.click();
                      document.body.removeChild(link);
                    }}
                  >
                    <Icon
                      icon={download}
                      height={24}
                      color="rgba(236,110,35,1)"
                    />
                  </ButtonNext>
                </span>
              ) : (
                ""
              )
            ) : (
              ""
            )}
            <hr className="my-4 w-50 mx-auto" />
            <p className="px-5 text-center">
              <LoremIpsum2>{t("record_again")} :</LoremIpsum2>
              <Link style={{ textDecoration: "none" }} to="/">
                <LoremIpsum3>
                  {" "}
                  <b>{t("home")}</b>
                </LoremIpsum3>
              </Link>
            </p>
          </div>
          <div className="col-md-2"></div>
        </WhiteRect>
      </Stack>
    </>
  );
}

const Stack = styled.div`
flex-grow: 1
  background-color: rgba(255, 255, 255, 1);
`;

const WhiteRect = styled.div``;

const LoremIpsum = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: #2ba049;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  text-align: center;
  line-height: 24px;
  margin: 0;
`;

const Caption = styled.p`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  text-align: center;
  line-height: 24px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum2 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum3 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  color: #2ba049;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

const LogoDiv = styled.div`
  padding: 24px;
`;

const LogoImg = styled.div`
  height: 80px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;
const ButtonNext = styled.button`
  background-color: #ffffff;
  height: 48px;
  width: 48px;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.85);
`;

export default ThankYou;
