import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Icon } from "@iconify/react";
import chevronRight from "@iconify-icons/mdi/chevron-right";
import closeIcon from "@iconify-icons/mdi/close";
import languageIcon from "@iconify-icons/uil/language";

import "./BottomNavigationBar.css";

const languages = [
  {
    code: "en",
    name: "English",
  },
  {
    code: "hin",
    name: "हिंदी",
  },
  {
    code: "asm",
    name: "অসমীয়া",
  },
  {
    code: "tel",
    name: "తెలుగు",
  },
  {
    code: "guj",
    name: "ગુજરાતી",
  },
  {
    code: "pun",
    name: "ਪੰਜਾਬੀ",
  },
  {
    code: "mal",
    name: "മലയാളം",
  },
  {
    code: "ori",
    name: "ଓଡିଆ",
  },
  {
    code: "mar",
    name: "मराठी",
  },
  {
    code: "kan",
    name: "ಕನ್ನಡ",
  },
  {
    code: "ban",
    name: "বাংলা",
  },
  {
    code: "tam",
    name: "தமிழ்",
  },
];

export default function BottomNavigationBar({ nextScreen, onClickNext }) {
  const currentLanguageCode = i18next.language;
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);
  const { t } = useTranslation();

  const [show, setshow] = useState("false");

  useEffect(() => {}, [currentLanguage, t]);

  return (
    <BottomGreen className="w-100">
      <div className="w-100 d-flex justify-content-between">
        <div>
          <div
            className={`na_lang_options_div border  ${
              show ? "" : "na_lang_options_div_active"
            }`}
          >
            {languages.map(({ code, name }, index) => (
              <div key={index}>
                <div
                  className="na_lang_options px-4 py-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    i18next.changeLanguage(code);
                    setshow((show) => !show);
                    localStorage.setItem("lang", code);
                  }}
                >
                  {currentLanguageCode === code ? (
                    <div
                      className="na_lang_option"
                      style={{ fontWeight: "bold" }}
                    >
                      {name}
                    </div>
                  ) : (
                    <div className="na_lang_option">{name}</div>
                  )}
                </div>

                {index === languages.length - 1 ? (
                  ""
                ) : (
                  <hr style={{ margin: "0px 16px 0px 16px" }} />
                )}
              </div>
            ))}
          </div>
          <ButtonNext onClick={() => setshow((show) => !show)}>
            {show ? (
              <Icon
                icon={languageIcon}
                height={24}
                color="rgba(236,110,35,1)"
              />
            ) : (
              <Icon icon={closeIcon} height={24} color="rgba(236,110,35,1)" />
            )}
            <LangText>{t("language")}</LangText>
          </ButtonNext>
        </div>
        <div className="mt-auto d-flex align-items-end">
          <Link to={nextScreen}>
            <ButtonNext onClick={onClickNext} className="btn">
              <LangText>{t("proceed")}</LangText>
              <Icon
                icon={chevronRight}
                height={24}
                color="rgba(236,110,35,1)"
              />
            </ButtonNext>
          </Link>
        </div>
      </div>
    </BottomGreen>
  );
}

const BottomGreen = styled.div`
  padding: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  transition: all 300ms ease-in;
`;

const ButtonNext = styled.button`
  background-color: #ffffff;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
`;

const LangText = styled.span`
  font-size: 0.8rem;
  padding-left: 8px;
  color: rgba(236, 110, 35, 1);
  font-weight: bold;
`;
