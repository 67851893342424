import React from "react";
import styled from "styled-components";
import "bootstrap/dist/css/bootstrap.min.css";
import HeaderImg1 from "../assets/images/img1.png";
import Banner from "../assets/images/banner.jpg";
import ModiZeeImage from "../assets/images/ModiZee.jpg";
import Vidbg from "../assets/images/vidbg.png";
import MyMobile from "./MyMobile";
import MyDesktop from "./MyDesktop";
import TitleVideo from "../assets/TitleVideo.mp4";

class MyComponent extends React.Component {
  state = {
    currentLocation: window.location.pathname,
  };

  render() {
    return (
      <>
        <MyMobile>
          <GreenHead src={HeaderImg1}></GreenHead>

          {this.state.currentLocation === "/" ? (
            <>
              <GreenHead src={Banner}></GreenHead>

              <video
                style={{ marginBottom: "-7px" }}
                width="100%"
                controls
                autoPlay={false}
                poster={Vidbg}
              >
                <source src={TitleVideo} type="video/mp4" />
              </video>
            </>
          ) : null}
        </MyMobile>
        <MyDesktop>
          <GreenHead className="header-top" src={HeaderImg1}></GreenHead>
          <div className="container header-banner">
            {this.state.currentLocation === "/" ? (
              <div className="row">
                <div className="col-6">
                  <ModiZee
                    style={{ backgroundImage: `url(${ModiZeeImage})` }}
                  ></ModiZee>
                </div>
                <div className="col-6 px-0" style={{ marginBottom: "-7px" }}>
                  <video
                    className="mb-0 pb-0"
                    width="100%"
                    controls
                    autoPlay={false}
                    poster={Vidbg}
                  >
                    <source src={TitleVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
            ) : null}
          </div>
        </MyDesktop>
      </>
    );
  }
}

// here app catches the suspense from page in case translations are not yet loaded
export default function MyHeader() {
  return <MyComponent />;
}

const GreenHead = styled.img`
  width: 100%;
`;

const ModiZee = styled.div`
  width: 100%;
  height: 100%;
  background-position: bottom;
  background-size: contain;
  background-repeat: no-repeat;
  margin-bottom: 10px;
`;
