import React, { Suspense } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import "./screens/main.css";
import Firstscreen from "./screens/Firstscreen";
import SecondScreen from "./screens/SecondScreen";
import ThirdScreen from "./screens/ThirdScreen";
import BottomImg from "./assets/images/img2.png";
import ErrorPage from "./screens/ErrorPage";
import ThankYou from "./screens/ThankYou";
import UserPermissions from "./screens/UserPermissions";
import Terms from "./screens/Terms";
import Policy from "./screens/Policy";
import VideoGrid from "./screens/VideoGrid";
import MyMobile from "./Components/MyMobile";
import BujurgoUpload from "./screens/BujurgoUpload";
import ThankYou2 from "./screens/ThankYou2";

function MyApp() {
  return (
    <Router>
      <div style={{ flexGrow: 1 }}>
        <Switch>
          <Route path="/" exact component={Firstscreen} />
          <Route path="/Info/" exact component={SecondScreen} />
          <Route path="/Record/" exact component={ThirdScreen} />
          <Route path="/UserPermissions/" exact component={UserPermissions} />
          <Route path="/thankYou/" exact component={ThankYou} />
          <Route path="/terms/" exact component={Terms} />
          <Route path="/policy/" exact component={Policy} />
          <Route path="/videogrid" exact component={VideoGrid} />
          <Route path="/bujurgokibaat/" exact component={BujurgoUpload} />
          <Route path="/uploadComplete/" exact component={ThankYou2} />
          <Route component={ErrorPage} />
        </Switch>
      </div>
      <MyMobile>
        <div className="py-4"></div>
      </MyMobile>

      <BottomTri src={BottomImg}></BottomTri>
    </Router>
  );
}

// loading component for suspense fallback
const Loader = () => (
  <>
    <div
      className="container h-100 d-flex justify-content-center"
      style={{ flexGrow: 1 }}
    >
      <div className="d-flex align-items-center h-100 ">
        <div className="spinner-border text-primary" role="status">
          <span className="sr-only"></span>
        </div>
      </div>
    </div>
  </>
);

// here app catches the suspense from page in case translations are not yet loaded
export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <MyApp />
    </Suspense>
  );
}

const BottomTri = styled.img`
  width: 100%;
  display: flex;
  z-index: -50;
`;
