import React from "react";
import styled from "styled-components";
import MyDesktop from "../Components/MyDesktop";
import { Icon } from "@iconify/react";
import chevronRight from "@iconify-icons/mdi/chevron-right";
import MyMobile from "../Components/MyMobile";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router-dom";
import MyHeader from "../Components/MyHeader";

var nextScreen = "/Record";

class SecondScreen extends React.Component {
  state = {
    name: "",
    gender: "",
    age: "<18",
    country: "IND",
    inState: "",
    Warning: false,
  };

  componentDidMount() {
    this.nameInput.focus();
  }

  handleInputChange(property) {
    return (e) => {
      this.setState({
        [property]: e.target.value,
      });
    };
  }

  setAge(e) {
    this.setState({
      age: e.target.value,
    });
  }

  handleCountryChange(e) {
    this.setState({ country: e.target.value });
  }

  handleInStateChange(e) {
    this.setState({ inState: e.target.value });
  }

  onFinish = (e) => {
    const { name, age, country, inState } = this.state;
    localStorage.setItem("user", name);
    // localStorage.setItem("gender", gender);
    localStorage.setItem("age", age);
    localStorage.setItem("country", country);
    localStorage.setItem("inState", inState);
    this.setState({ Warning: true });
  };

  render() {
    return (
      <>
        <MyHeader />
        <Stack className="container">
          <WhiteRect className="row">
            <div className="col-md-2"></div>
            <div className="col-md-8 py-5">
              <GreenText className="mb-3 text-center" htmlFor="name">
                {this.props.t("please_enter_your_details")}
              </GreenText>

              <div className="form">
                <div className="mb-3">
                  <label htmlFor="name">{this.props.t("full_name")}</label>
                  <input
                    type="text"
                    className="form-control col-md-3 mx-auto"
                    id="name"
                    placeholder={this.props.t("your_name")}
                    required
                    value={this.state.name === null ? "" : this.state.name}
                    defaultValue={this.state.country}
                    ref={(input) => {
                      this.nameInput = input;
                    }}
                    onChange={this.handleInputChange("name")}
                  />
                  {this.state.Warning === true &&
                  (this.state.name === "" || this.state.name === null) ? (
                    <span className="text-danger w-100">
                      {this.props.t("name_is_required")}
                    </span>
                  ) : null}
                </div>

                <div
                  className="mb-3 row"
                  onChange={this.setAge.bind(this)}
                  value={this.state.age}
                >
                  <label htmlFor="inlineRadio1">
                    {this.props.t("age_group")}
                  </label>
                  <div className="col-lg-3 col-6 my-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio1"
                        value="<18"
                        defaultChecked
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio1"
                      >
                        {this.props.t("under_18")}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 my-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio2"
                        value="18-30"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio2"
                      >
                        {this.props.t("18_to_30")}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 my-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio3"
                        value="31-45"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio3"
                      >
                        {this.props.t("31_to_45")}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-3 col-6 my-2">
                    <div className="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="inlineRadioOptions"
                        id="inlineRadio4"
                        value=">45"
                      />
                      <label
                        className="form-check-label"
                        htmlFor="inlineRadio4"
                      >
                        {this.props.t("above_45")}
                      </label>
                    </div>
                  </div>
                </div>

                {/* Country Dropdown */}

                <div className="form-group mb-3 mx-auto">
                  <label htmlFor="inputCountry">
                    {this.props.t("country")} / {this.props.t("region")}
                  </label>
                  <select
                    value={this.state.country}
                    onChange={this.handleCountryChange.bind(this)}
                    className="form-control"
                    id="inputCountry"
                  >
                    <option value="AFG">Afghanistan</option>
                    <option value="ALA">Åland Islands</option>
                    <option value="ALB">Albania</option>
                    <option value="DZA">Algeria</option>
                    <option value="AND">Andorra</option>
                    <option value="AGO">Angola</option>
                    <option value="ATA">Antarctica</option>
                    <option value="ATG">Antigua and Barbuda</option>
                    <option value="ARG">Argentina</option>
                    <option value="ARM">Armenia</option>
                    <option value="AUS">Australia</option>
                    <option value="AUT">Austria</option>
                    <option value="AZE">Azerbaijan</option>
                    <option value="BHS">Bahamas</option>
                    <option value="BHR">Bahrain</option>
                    <option value="BGD">Bangladesh</option>
                    <option value="BRB">Barbados</option>
                    <option value="BLR">Belarus</option>
                    <option value="BEL">Belgium</option>
                    <option value="BLZ">Belize</option>
                    <option value="BEN">Benin</option>
                    <option value="BTN">Bhutan</option>
                    <option value="BOL">Bolivia, Plurinational State of</option>
                    <option value="BIH">Bosnia and Herzegovina</option>
                    <option value="BWA">Botswana</option>
                    <option value="BRA">Brazil</option>
                    <option value="BRN">Brunei Darussalam</option>
                    <option value="BGR">Bulgaria</option>
                    <option value="BFA">Burkina Faso</option>
                    <option value="BDI">Burundi</option>
                    <option value="KHM">Cambodia</option>
                    <option value="CMR">Cameroon</option>
                    <option value="CAN">Canada</option>
                    <option value="CPV">Cape Verde</option>
                    <option value="CAF">Central African Republic</option>
                    <option value="TCD">Chad</option>
                    <option value="CHL">Chile</option>
                    <option value="CHN">China</option>
                    <option value="COL">Colombia</option>
                    <option value="COM">Comoros</option>
                    <option value="COG">Congo</option>
                    <option value="COD">Congo</option>
                    <option value="CRI">Costa Rica</option>
                    <option value="CIV">Côte d'Ivoire</option>
                    <option value="HRV">Croatia</option>
                    <option value="CUB">Cuba</option>
                    <option value="CYP">Cyprus</option>
                    <option value="CZE">Czech Republic</option>
                    <option value="DNK">Denmark</option>
                    <option value="DJI">Djibouti</option>
                    <option value="DMA">Dominica</option>
                    <option value="DOM">Dominican Republic</option>
                    <option value="ECU">Ecuador</option>
                    <option value="EGY">Egypt</option>
                    <option value="SLV">El Salvador</option>
                    <option value="GNQ">Equatorial Guinea</option>
                    <option value="ERI">Eritrea</option>
                    <option value="EST">Estonia</option>
                    <option value="ETH">Ethiopia</option>
                    <option value="FJI">Fiji</option>
                    <option value="FIN">Finland</option>
                    <option value="FRA">France</option>
                    <option value="GAB">Gabon</option>
                    <option value="GMB">Gambia</option>
                    <option value="GEO">Georgia</option>
                    <option value="DEU">Germany</option>
                    <option value="GHA">Ghana</option>
                    <option value="GRC">Greece</option>
                    <option value="GRD">Grenada</option>
                    <option value="GTM">Guatemala</option>
                    <option value="GGY">Guernsey</option>
                    <option value="GIN">Guinea</option>
                    <option value="GNB">Guinea-Bissau</option>
                    <option value="GUY">Guyana</option>
                    <option value="HTI">Haiti</option>
                    <option value="VAT">Holy See (Vatican City State)</option>
                    <option value="HND">Honduras</option>
                    <option value="HUN">Hungary</option>
                    <option value="ISL">Iceland</option>
                    <option value="IND">India</option>
                    <option value="IDN">Indonesia</option>
                    <option value="IRN">Iran</option>
                    <option value="IRQ">Iraq</option>
                    <option value="IRL">Ireland</option>
                    <option value="IMN">Isle of Man</option>
                    <option value="ISR">Israel</option>
                    <option value="ITA">Italy</option>
                    <option value="JAM">Jamaica</option>
                    <option value="JPN">Japan</option>
                    <option value="JEY">Jersey</option>
                    <option value="JOR">Jordan</option>
                    <option value="KAZ">Kazakhstan</option>
                    <option value="KEN">Kenya</option>
                    <option value="KIR">Kiribati</option>
                    <option value="PRK">Korea</option>
                    <option value="KOR">Korea</option>
                    <option value="KWT">Kuwait</option>
                    <option value="KGZ">Kyrgyzstan</option>
                    <option value="LAO">
                      Lao People's Democratic Republic
                    </option>
                    <option value="LVA">Latvia</option>
                    <option value="LBN">Lebanon</option>
                    <option value="LSO">Lesotho</option>
                    <option value="LBR">Liberia</option>
                    <option value="LBY">Libya</option>
                    <option value="LIE">Liechtenstein</option>
                    <option value="LTU">Lithuania</option>
                    <option value="LUX">Luxembourg</option>
                    <option value="MKD">Macedonia</option>
                    <option value="MDG">Madagascar</option>
                    <option value="MWI">Malawi</option>
                    <option value="MYS">Malaysia</option>
                    <option value="MDV">Maldives</option>
                    <option value="MLI">Mali</option>
                    <option value="MLT">Malta</option>
                    <option value="MHL">Marshall Islands</option>
                    <option value="MRT">Mauritania</option>
                    <option value="MUS">Mauritius</option>
                    <option value="MEX">Mexico</option>
                    <option value="FSM">Micronesia</option>
                    <option value="MDA">Moldova</option>
                    <option value="MCO">Monaco</option>
                    <option value="MNG">Mongolia</option>
                    <option value="MNE">Montenegro</option>
                    <option value="MAR">Morocco</option>
                    <option value="MOZ">Mozambique</option>
                    <option value="MMR">Myanmar</option>
                    <option value="NAM">Namibia</option>
                    <option value="NRU">Nauru</option>
                    <option value="NPL">Nepal</option>
                    <option value="NLD">Netherlands</option>
                    <option value="NZL">New Zealand</option>
                    <option value="NIC">Nicaragua</option>
                    <option value="NER">Niger</option>
                    <option value="NGA">Nigeria</option>
                    <option value="NOR">Norway</option>
                    <option value="OMN">Oman</option>
                    <option value="PAK">Pakistan</option>
                    <option value="PLW">Palau</option>
                    <option value="PSE">Palestine</option>
                    <option value="PAN">Panama</option>
                    <option value="PNG">Papua New Guinea</option>
                    <option value="PRY">Paraguay</option>
                    <option value="PER">Peru</option>
                    <option value="PHL">Philippines</option>
                    <option value="POL">Poland</option>
                    <option value="PRT">Portugal</option>
                    <option value="QAT">Qatar</option>
                    <option value="ROU">Romania</option>
                    <option value="RUS">Russian Federation</option>
                    <option value="RWA">Rwanda</option>
                    <option value="KNA">Saint Kitts and Nevis</option>
                    <option value="LCA">Saint Lucia</option>
                    <option value="VCT">
                      Saint Vincent and the Grenadines
                    </option>
                    <option value="WSM">Samoa</option>
                    <option value="SMR">San Marino</option>
                    <option value="STP">Sao Tome and Principe</option>
                    <option value="SAU">Saudi Arabia</option>
                    <option value="SEN">Senegal</option>
                    <option value="SRB">Serbia</option>
                    <option value="SYC">Seychelles</option>
                    <option value="SLE">Sierra Leone</option>
                    <option value="SGP">Singapore</option>
                    <option value="SVK">Slovakia</option>
                    <option value="SVN">Slovenia</option>
                    <option value="SLB">Solomon Islands</option>
                    <option value="SOM">Somalia</option>
                    <option value="ZAF">South Africa</option>

                    <option value="SSD">South Sudan</option>
                    <option value="ESP">Spain</option>
                    <option value="LKA">Sri Lanka</option>
                    <option value="SDN">Sudan</option>
                    <option value="SUR">Suriname</option>
                    <option value="SWZ">Swaziland</option>
                    <option value="SWE">Sweden</option>
                    <option value="CHE">Switzerland</option>
                    <option value="SYR">Syrian Arab Republic</option>
                    <option value="TWN">Taiwan</option>
                    <option value="TJK">Tajikistan</option>
                    <option value="TZA">Tanzania</option>
                    <option value="THA">Thailand</option>
                    <option value="TLS">Timor-Leste</option>
                    <option value="TGO">Togo</option>
                    <option value="TON">Tonga</option>
                    <option value="TTO">Trinidad and Tobago</option>
                    <option value="TUN">Tunisia</option>
                    <option value="TUR">Turkey</option>
                    <option value="TKM">Turkmenistan</option>
                    <option value="TUV">Tuvalu</option>
                    <option value="UGA">Uganda</option>
                    <option value="UKR">Ukraine</option>
                    <option value="ARE">United Arab Emirates</option>
                    <option value="GBR">United Kingdom</option>
                    <option value="USA">United States</option>
                    <option value="UMI">
                      United States Minor Outlying Islands
                    </option>
                    <option value="URY">Uruguay</option>
                    <option value="UZB">Uzbekistan</option>
                    <option value="VUT">Vanuatu</option>
                    <option value="VEN">Venezuela</option>
                    <option value="VNM">Viet Nam</option>
                    <option value="YEM">Yemen</option>
                    <option value="ZMB">Zambia</option>
                    <option value="ZWE">Zimbabwe</option>
                  </select>
                </div>

                {/* InState Dropdown */}
                {this.state.country === "IND" ? (
                  <div className="form-group mb-3 mx-auto">
                    <label htmlFor="inputState">
                      {this.props.t("in_state")}
                    </label>
                    <select
                      value={this.state.inState}
                      onChange={this.handleInStateChange.bind(this)}
                      className="form-control"
                      id="inputState"
                      // defaultValue={this.state.inState}
                      required
                    >
                      <option hidden>{this.props.t("select_state")}</option>
                      <option value="AP">Andhra Pradesh</option>
                      <option value="AN">Andaman and Nicobar Islands</option>
                      <option value="AR">Arunachal Pradesh</option>
                      <option value="AS">Assam</option>
                      <option value="BR">Bihar</option>
                      <option value="CH">Chandigarh</option>
                      <option value="CT">Chhattisgarh</option>
                      <option value="DN">Dadar and Nagar Haveli</option>
                      <option value="DD">Daman and Diu</option>
                      <option value="DL">Delhi</option>
                      <option value="GA">Goa</option>
                      <option value="GJ">Gujarat</option>
                      <option value="HR">Haryana</option>
                      <option value="HP">Himachal Pradesh</option>
                      <option value="JK">Jammu and Kashmir</option>
                      <option value="JH">Jharkhand</option>
                      <option value="KA">Karnataka</option>
                      <option value="KL">Kerala</option>
                      <option value="LK">Ladakh</option>
                      <option value="LD">Lakshadweep</option>
                      <option value="MP">Madhya Pradesh</option>
                      <option value="MH">Maharashtra</option>
                      <option value="MN">Manipur</option>
                      <option value="ML">Meghalaya</option>
                      <option value="MZ">Mizoram</option>
                      <option value="NL">Nagaland</option>
                      <option value="OR">Odisha</option>
                      <option value="PY">Puducherry</option>
                      <option value="PB">Punjab</option>
                      <option value="RJ">Rajasthan</option>
                      <option value="SK">Sikkim</option>
                      <option value="TN">Tamil Nadu</option>
                      <option value="TG">Telangana</option>
                      <option value="TR">Tripura</option>
                      <option value="UP">Uttar Pradesh</option>
                      <option value="UT">Uttarakhand</option>
                      <option value="WB">West Bengal</option>
                    </select>
                    {this.state.Warning === true &&
                    this.state.inState === "" ? (
                      <span className="text-danger w-100">
                        {this.props.t("state_required")}
                      </span>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <MyDesktop className="align-center">
                <div className="text-center">
                  <NextButton
                    className="btn"
                    onClick={
                      this.state.name &&
                      (this.state.country === "IND" ? this.state.inState : true)
                        ? () => {
                            this.onFinish();
                            this.props.history.push(nextScreen);
                          }
                        : () => {
                            this.onFinish();
                          }
                    }
                  >
                    {this.props.t("lets_sing")}

                    <Icon icon={chevronRight} height={24} />
                  </NextButton>
                </div>
              </MyDesktop>
            </div>
            <div className="col-md-2"></div>
          </WhiteRect>
          <MyMobile>
            <BottomGreen>
              <ButtonNext
                className="btn"
                onClick={
                  this.state.name &&
                  (this.state.country === "IND" ? this.state.inState : true)
                    ? () => {
                        this.onFinish();
                        this.props.history.push(nextScreen);
                      }
                    : () => {
                        this.onFinish();
                      }
                }
              >
                <LangText> {this.props.t("lets_sing")}</LangText>

                <Icon
                  icon={chevronRight}
                  height={24}
                  color="rgba(236,110,35,1)"
                />
              </ButtonNext>
            </BottomGreen>
          </MyMobile>
        </Stack>
      </>
    );
  }
}

const NextButton = styled.button`
  font-weight: bold;
  color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background: #fd7034;
  &:hover {
    color: #ffffff;
    background: #ff4d00;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }
`;

const Stack = styled.div`
  flex-direction: column;
  background-color: rgba(255, 255, 255, 1);
`;

const WhiteRect = styled.div``;

const GreenText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: rgba(43, 160, 73, 1);
  z-index: 1000;
`;

const BottomGreen = styled.div`
  padding: 16px;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
`;

const ButtonNext = styled.button`
  background-color: #ffffff;
  border: none;
  border-radius: 24px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  padding: 0.75rem 1.25rem 0.75rem 1.25rem;
`;

const LangText = styled.span`
  font-size: 0.8rem;
  padding-left: 8px;
  color: rgba(236, 110, 35, 1);
  font-weight: bold;
`;

export default withTranslation()(withRouter(SecondScreen));
