import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import MyHeader from "../Components/MyHeader";
import { withTranslation } from "react-i18next";
import MyDesktop from "../Components/MyDesktop";
import MyMobile from "../Components/MyMobile";

class VideoGrid extends React.Component {
  state = {
    currentIndex: 4,
    show: false,

    arraylength: 20,
  };

  render() {
    const { currentIndex, show } = this.state;

    window.onscroll = () => {
      var scrollPos = window.pageYOffset;
      var pageHeight = document.body.scrollHeight - window.innerHeight - 100;

      if (scrollPos > pageHeight) {
        this.setState({ arraylength: this.state.arraylength + 20 });
      }
    };

    return (
      <>
        <MyHeader />
        <Stack className="container">
          <LoremIpsum>{this.props.t("featued_videos")}</LoremIpsum>
          <Caption className="text-center pb-4">
            Upload your video to get featured here.
          </Caption>

          <div className="row p-2">
            {this.props
              .t("videoData", {
                returnObjects: true,
              })
              .slice(0, this.state.arraylength)
              .map((item, index) => (
                <div className="col-md-2 p-2 col-6" key={index}>
                  <Card
                    className="h-100"
                    onClick={() => {
                      this.setState({ currentIndex: index });
                      this.setState({ show: true });
                    }}
                  >
                    <div
                      style={{
                        backgroundImage: `url(${item.thumbnailUrl})`,
                        height: 0,
                        width: "100%",
                        paddingBottom: "100%",
                        backgroundSize: "cover",
                      }}
                    ></div>
                    <div className="p-1">
                      <CardText>
                        <b>Name: </b>
                        {item.name}
                      </CardText>
                      {item.state ? (
                        <CardText>
                          <b>State: </b>
                          {item.state}
                        </CardText>
                      ) : null}

                      <CardText>
                        <b>Country: </b>
                        {item.country}
                      </CardText>
                    </div>
                  </Card>
                </div>
              ))}
          </div>

          <hr className="my-4 w-50 mx-auto" />
          <p className="px-5 text-center">
            <Link style={{ textDecoration: "none" }} to="/">
              <LoremIpsum3>
                <b>{this.props.t("home")}</b>
              </LoremIpsum3>
            </Link>
          </p>
        </Stack>

        {/* Video Overlay */}
        {show ? (
          <>
            <VideoOverlay
              onClick={() => {
                this.setState({ show: false });
              }}
              className="position-fixed"
            >
              <div
                className="h-100 w-100 position-relative my-auto text-center"
                style={{ zIndex: 2000 }}
              >
                <MyDesktop>
                  <video
                    style={{ border: "4px solid black" }}
                    height="100%"
                    controls
                    autoPlay
                    poster={
                      this.props.t("videoData", {
                        returnObjects: true,
                      })[currentIndex].thumbnailUrl
                    }
                  >
                    <source
                      src={
                        this.props.t("videoData", {
                          returnObjects: true,
                        })[currentIndex].link
                      }
                      type="video/mp4"
                    />
                  </video>
                </MyDesktop>
                <MyMobile>
                  <video
                    style={{ border: "2px solid black" }}
                    width="100%"
                    controls
                    autoPlay
                  >
                    <source
                      src={
                        this.props.t("videoData", {
                          returnObjects: true,
                        })[currentIndex].link
                      }
                      type="video/mp4"
                    />
                  </video>
                </MyMobile>
              </div>
            </VideoOverlay>
          </>
        ) : null}
      </>
    );
  }
}

const VideoOverlay = styled.div`
  position: fixed;
  background: rgba(250, 190, 118, 0.5);
  backdrop-filter: blur(4px);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 5%;
  width: 100%;
  height: 100%;
  z-index: 1500;
`;

const Card = styled.div`
  background: #efefef;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  cursor: pointer;
  border: 2px solid #efefef;
  box-sizing: border-box;
  overflow: hidden;
  &:hover {
    background: #fd7034;
    border: 2px solid #fd7034;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    color: #ffffff;
  }
`;

const CardText = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-size: 0.65rem;
  line-height: 136.9%;
  padding-top: 0.25rem;
`;

const Stack = styled.div`
flex-grow: 1
  background-color: rgba(255, 255, 255, 1);
`;

const LoremIpsum = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 700;
  color: #2ba049;
  text-align: center;
  margin: 0;
  font-size: 1.5rem;
`;

const Caption = styled.p`
  font-family: Montserrat;
  font-style: normal;
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  text-align: center;
  line-height: 24px;
  font-size: 0.75rem;
  margin: 0;
`;

const LoremIpsum3 = styled.span`
  font-family: Montserrat;
  font-style: normal;
  color: #2ba049;
  width: auto;
  text-align: center;
  line-height: 20px;
  font-size: 0.75rem;
  margin: 0;
`;

export default withTranslation()(VideoGrid);
