import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BottomNavigationBar from "../Components/BottomNavigationBar";
import firebase from "firebase/app";
import "firebase/storage";
import "firebase/analytics";

import "firebase/app-check";
import MyMobile from "../Components/MyMobile";
import MyDesktop from "../Components/MyDesktop";

import { Icon } from "@iconify/react";
import chevronRight from "@iconify-icons/mdi/chevron-right";
import accountIcon from "@iconify-icons/mdi/account";
import recordIcon from "@iconify-icons/mdi/record";
import trayArrowUp from "@iconify-icons/mdi/tray-arrow-up";
import trayArrowDown from "@iconify-icons/mdi/tray-arrow-down";
import playBoxMultipleOutline from "@iconify-icons/mdi/play-box-multiple-outline";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DesktopLanguage from "../Components/DekstopLanguage";
import MyHeader from "../Components/MyHeader";

var config = {
  apiKey: "AIzaSyBu4yXfTCud9NWzE1juCyFSJUrF9rbjvgs",
  authDomain: "national-anthem-sync.firebaseapp.com",
  projectId: "national-anthem-sync",
  storageBucket: "national-anthem-sync.appspot.com",
  messagingSenderId: "285517886455",
  appId: "1:285517886455:web:206a6d769885700a7df4f2",
  measurementId: "G-4VML64PFFC",
};

if (!firebase.apps.length) {
  firebase.initializeApp(config);
  firebase.analytics();
  // const appCheck = firebase.appCheck();
  // appCheck.activate("6Ledoq0bAAAAANwaAoESYP2bUJh1nzeaSj55oWDK");
} else {
  try {
    firebase.app(); // if already initialized, use that one
    firebase.analytics();
    // const appCheck = firebase.appCheck();
    // appCheck.activate("6Ledoq0bAAAAANwaAoESYP2bUJh1nzeaSj55oWDK");
  } catch (e) {
    console.log("Exception while registering firebase " + e);
  }
}

var nextScreen = "/Info";

const StepsBox = (props) => (
  <StepBoxDiv className="d-flex flex-column h-100">
    <div className="flex-grow-1">
      <StartEndTitle>{props.stepNo}</StartEndTitle>
      <StartEndDate>{props.stepCaption}</StartEndDate>
    </div>

    <StepBoxDiv2>{props.children}</StepBoxDiv2>
  </StepBoxDiv>
);

const StepBoxDiv = styled.div`
  background: #ffffff;
  border: 0.8px solid #3a526a;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 0.5rem;
  text-align: left;
`;

const StartEndTitle = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
  margin-bottom: 0.2rem;
`;

const StartEndDate = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 10px;
  color: #3a526a;
`;

const StepBoxDiv2 = styled.div`
  border: 0.5px solid #096dd9;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0;
  color: #096dd9;
  text-align: center;
`;

function Firstscreen(props) {
  const { t } = useTranslation();
  const [counter, setCounter] = useState(null);

  // function animateValue(start, end, duration) {
  //   if (start === end) return;
  //   var range = end - start;
  //   var current = start;
  //   var increment = end > start ? 1 : -1;
  //   var stepTime = Math.abs(Math.floor(duration / range));
  //   //var obj = document.getElementById(id);
  //   var timer = setInterval(function () {
  //     current += increment;
  //     //obj.innerHTML = current;
  //     setCounter(current)
  //     if (current == end) {
  //       clearInterval(timer);
  //     }
  //   }, stepTime);
  // }

  function getPageViewCount() {
    //  Function to read the Counts
    // setInterval(function () {
    var xhttp = new XMLHttpRequest();
    try {
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          var counterFromApi = /\d/.test(this.responseText)
            ? this.responseText
            : null;

          setCounter(counterFromApi);
        }
      };
      xhttp.open(
        "GET",
        "https://us-central1-national-anthem-sync.cloudfunctions.net/visitor_count",
        true
      );
      xhttp.send();
    } catch (e) {
      console.log("Error fetching Counter");
    }
    // }, 2000);

    // Function to wite the visit Count
    try {
      xhttp.onreadystatechange = function () {
        if (this.readyState === 4 && this.status === 200) {
          console.log("Count Updated");
        }
      };
      xhttp.open(
        "GET",
        "https://us-central1-national-anthem-sync.cloudfunctions.net/date",
        true
      );
      xhttp.send();
    } catch (e) {
      console.log("Error fetching Counter");
    }
  }

  useEffect(() => {
    getPageViewCount();
  }, []);

  // const onMouseEnter = (e) => {
  //   var element = document.getElementById("my-marquee");
  //   element.stop();
  // };

  // const onMouseLeave = (e) => {
  //   var element = document.getElementById("my-marquee");
  //   element.start();
  // };

  return (
    <>
      <MyHeader />
      <InfoStrip className="container-fluid shadow-sm">
        <div className="row">
          <GreenText className="col-md-6 col-12 bg-white py-2 text-center border">
            <a href="https://digitaltribute.in">
              <span className="px-3">{t("digital_tribute")}  <span className="blinking">New</span></span>
            </a>
          </GreenText>
          <GreenText className="col-md-6 col-12 bg-white py-2 text-center border">
            <Link to="/videogrid">
              <Icon icon={playBoxMultipleOutline} height={24} />
              <span className="px-3">{t("featued_videos")}</span>
            </Link>
          </GreenText>
        </div>
      </InfoStrip>
      <Stack className="container">
        <WhiteRect className="row">
          <div className="col-md-12">
            <div className="pt-md-4 pt-3">
              <MyMobile>
                <LoremIpsumMob>{t("as_the_nation_celeb")}</LoremIpsumMob>
              </MyMobile>
              <MyDesktop>
                <LoremIpsumDesk className="mb-5">
                  {t("as_the_nation_celeb")}
                </LoremIpsumDesk>
              </MyDesktop>
            </div>
            <div className="row px-md-0 px-5">
              <div className="col-md-6">
                <MyMobile>
                  <LoremIpsum2Mob>
                    <b>"{t("let_us")}"</b>
                    <br />
                    {t("sing_the_national")}
                  </LoremIpsum2Mob>
                </MyMobile>

                <MyDesktop>
                  <LoremIpsum2Desk>
                    <b>"{t("let_us")}"</b>
                    <br />
                    {t("sing_the_national")}
                  </LoremIpsum2Desk>
                </MyDesktop>
              </div>

              <div className="col-md-6">
                <div className="row">
                  <div className="col-md-3 col-6 pb-4">
                    <StepsBox
                      stepNo={t("step_1")}
                      stepCaption={t("enter_the_details")}
                    >
                      <Icon icon={accountIcon} />
                      <StepBtnText>Name</StepBtnText>
                    </StepsBox>
                  </div>
                  <div className="col-md-3 col-6 pb-4">
                    <StepsBox
                      stepNo={t("step_2")}
                      stepCaption={t("stand_and_record")}
                    >
                      <StepBtnText>REC</StepBtnText>
                      <Icon color="#FF0000" icon={recordIcon} />
                    </StepsBox>
                  </div>
                  <div className="col-md-3 col-6 pb-4">
                    <StepsBox stepNo={t("step_3")} stepCaption={t("upload")}>
                      <Icon icon={trayArrowUp} />
                    </StepsBox>
                  </div>
                  <div className="col-md-3 col-6 pb-4">
                    <StepsBox
                      stepNo={t("step_4")}
                      stepCaption={t("download_certificate")}
                    >
                      <Icon icon={trayArrowDown} />
                    </StepsBox>
                  </div>
                </div>
              </div>
            </div>
            <MyDesktop>
              <div className="d-flex flex-row my-4">
                <div className="">
                  <DesktopLanguage />
                </div>
                <div className="px-3">
                  <Link to={nextScreen}>
                    <NextButton className="btn" to={nextScreen}>
                      {t("proceed")} <Icon icon={chevronRight} height={24} />
                    </NextButton>
                  </Link>
                </div>
              </div>
            </MyDesktop>
            <MyDesktop>
              <LoremIpsum3Desk>
                {t("a_compilation_of_the_uploaded")}
              </LoremIpsum3Desk>
            </MyDesktop>

            <MyMobile>
              <LoremIpsum3Mob className="px-5">
                {t("a_compilation_of_the_uploaded")}
              </LoremIpsum3Mob>
            </MyMobile>

            <div className="container d-flex justify-content-center">
              <div className="center">
                <TotalUserText className="mb-1">TOTAL USERS</TotalUserText>
                <p className="paragraph-text"></p>
                <span className="digit">25872516</span>
              </div>
            </div>

            <div className="container d-flex justify-content-center">
              <TermsPolicy>
                <Link to="/terms/">{t("terms_of_use")}</Link>
                <span> | </span>
                <Link to="/policy/">{t("privacy_policy")}</Link>
              </TermsPolicy>
            </div>
          </div>
        </WhiteRect>

        <MyMobile>
          <BottomNavigationBar nextScreen={nextScreen}></BottomNavigationBar>
        </MyMobile>
      </Stack>
    </>
  );
}

const Stack = styled.div`
flex-grow: 1
  background-color: rgba(255, 255, 255, 1);
  
`;

const InfoStrip = styled.div`
  font-family: Montserrat;
`;

const GreenText = styled.div`
  color: #89be4c;
  font-family: Montserrat;
  font-size: 0.8rem;
  font-weight: bold;
`;

const WhiteRect = styled.div``;

const StepBtnText = styled.span`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 0.6rem;
  line-height: 10px;
  color: #096dd9;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`;

const TotalUserText = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 12px;
  text-transform: uppercase;
  color: #000000;
`;
const LoremIpsumMob = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: rgba(236, 110, 35, 1);
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  text-align: center;
  line-height: 24px;
`;

const LoremIpsumDesk = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 121.4%;
  letter-spacing: 0.02em;
  color: #fd7034;
`;

const LoremIpsum2Mob = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #3a526a;
`;

const LoremIpsum2Desk = styled.p`
  font-family: Montserrat;
  font-style: italic;
  font-weight: 500;
  font-size: 21px;
  line-height: 35px;
  color: #3a526a;
`;

const LoremIpsum3Mob = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  text-align: center;

  color: #3a526a;
`;

const LoremIpsum3Desk = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  font-size: 1rem;
  line-height: 29px;
  text-align: left;
  color: #3a526a;
`;

const NextButton = styled.button`
  font-weight: bold;
  color: #ffffff;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  border-radius: 2px;
  background: #fd7034;
  &:hover {
    color: #ffffff;
    background: #ff4d00;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
  }
`;

const TermsPolicy = styled.p`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: rgba(171, 171, 171, 1);
  width: auto;
  padding-right: 48px;
  padding-left: 48px;
  font-size: 0.625rem;
  z-index: 1000;
  margin-bottom: 0;
  text-align: center;
`;

export default Firstscreen;
